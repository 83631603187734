*{
  transition: color 0.25s linear, background-color 0.25s linear, border-color 0.25s linear;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

/*Styling Scrolebar*/
::-webkit-scrollbar {
  width: 10px;
}

/*Track*/
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/*Handle*/
::-webkit-scrollbar-thumb {
  background: #888;
}

/*Handle on hover*/
::-webkit-scrollbar-track:hover {
  background: #555;
}

/*ECGViewer styles*/
.ecgviewer {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
}
.ecgviewer canvas {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#ecgviewerpreview {
  border: 1px solid black;
}

#printView {
  visibility: hidden;
}

@media print {
  .page .ecgviewer {
    height: 20cm !important;
  }

  #sidebar,
  #bottombar,
  #topbar,
  #result-main-page,
  .MuiButtonBase-root,
  .MuiInputBase-root,
  .ecg-viewer-toolbar {
    display: none !important;
  }

  .main-content-container {
    margin: 0 !important;
  }

  .ecg-viewer-container {
    height: 20cm !important;
    border: none !important;
  }

  .page {
    break-before: page;
  }

  .pageHeader {
    margin-bottom: 1cm !important;
  }

  #printView {
    visibility: visible;
  }

  @page {
    margin: 1cm 2cm !important;
  }
}
